<script lang="ts" setup>
import CardWithImage from '~/components/cards/CardWithImage.vue'
import ViewAllButton from '~/components/form-elements/ViewAllButton.vue'
import constants from '~/constants'
import Page from '~/enums/page'
import helpers from '~/helpers'

defineProps<{
  articlesData: any[]
  title: string
}>()

const articlesPath = Page.Articles

const router = useRouter()
</script>

<template>
  <section v-if="articlesData.length">
    <div class="container">
      <Heading element="h2" :path="articlesPath" :title="title" title-class="h1">
        <template #buttons>
          <ViewAllButton title="Все статьи" @click="router.push(articlesPath)" />
        </template>
      </Heading>
    </div>

    <Gallery :options="constants.galleryOptions2">
      <CardWithImage
        v-for="x in articlesData"
        :key="x.id"
        class="max-4-slides swiper-slide"
        :data="x"
        :path="helpers.getArticlePath(x)"
      />
    </Gallery>
  </section>
</template>

<style lang="scss" scoped>
@use 'assets/css/gallery';
</style>
