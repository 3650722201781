<script lang="ts" setup>
import type { EmblaCarouselType, EmblaOptionsType, EmblaPluginType } from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import emblaCarouselVue from 'embla-carousel-vue'

import Banner from '~/components/banner/Banner.vue'
import CarouselButton from '~/components/carousels/CarouselButton.vue'
import { useInteraction } from '~/composables/interaction'
import type { BannerData } from '~/types/banners'

defineProps<{
  bannersData: BannerData[]
  isDataLoading: boolean
}>()

const delayInMs = 5000 // 5000 ms is 5 seconds.
const options: EmblaOptionsType = { loop: true }
const plugins: EmblaPluginType[] = [Autoplay({ delay: delayInMs })]
const [emblaRef, emblaApi] = emblaCarouselVue(options, plugins)

const { onClick } = useInteraction()

const canScrollNext = ref(false)
const canScrollPrev = ref(false)
const selectedIndex = ref(0)

onMounted(() => {
  if (!emblaApi?.value) {
    return
  }

  emblaApi.value.on('init', onSelect)
  emblaApi.value.on('reInit', onSelect)
  emblaApi.value.on('select', onSelect)
})

function onSelect (emblaApi: EmblaCarouselType): void {
  canScrollNext.value = emblaApi.canScrollNext() ?? false
  canScrollPrev.value = emblaApi.canScrollPrev() ?? false
  selectedIndex.value = emblaApi.selectedScrollSnap() ?? 0
}

function scrollNext (): void {
  stopAutoplay()
  emblaApi?.value?.scrollNext()
}

function scrollPrev (): void {
  stopAutoplay()
  emblaApi?.value?.scrollPrev()
}

function scrollTo (index: number): void {
  stopAutoplay()
  emblaApi?.value?.scrollTo(index)
}

function stopAutoplay (): void {
  emblaApi?.value?.reInit(options, [])
}
</script>

<template>
  <section>
    <div class="container gallery-container">
      <div class="flex flex-col xl:flex-row">
        <div class="relative w-full overflow-hidden xl:h-[364px] xl:w-[1016px]">
          <div v-if="isDataLoading" class="mx-2 md:mx-0">
            <div
              class="
                animate-pulse rounded-2xl bg-main-gray-low pb-[56.25%]
                md:h-[364px] md:w-[1016px] md:rounded-3xl md:pb-0
              "
            />
          </div>

          <div v-show="!isDataLoading && bannersData.length" class="embla">
            <div ref="emblaRef" class="relative">
              <div class="flex space-x-2 px-2 md:space-x-4 md:px-0 lg:space-x-6">
                <div
                  v-for="x in bannersData"
                  :key="x.id"
                  class="flex-[0_0_100%] cursor-pointer"
                  @click="onClick(x)"
                >
                  <Banner :banner-data="x" />
                </div>
              </div>
            </div>
            <div class="absolute bottom-4 left-6 flex space-x-2 md:hidden">
              <button
                v-for="x in bannersData.length"
                :key="x"
                :aria-label="`Перейти на слайд ${x}`"
                class="size-[6px] rounded-full bg-white/70"
                :class="{ '!bg-white': selectedIndex === x - 1 }"
                @click="scrollTo(x - 1)"
              />
            </div>
            <div class="absolute bottom-8 right-8 hidden text-white md:flex md:items-center">
              <CarouselButton :is-next="false" @on-button-click="scrollPrev" />
              <span class="mx-4 text-xl [text-shadow:_0_0_4px_rgb(0_0_0_/_50%)]">
                {{ selectedIndex + 1 }}/<span class="text-xs">{{ bannersData.length }}</span>
              </span>
              <CarouselButton is-next @on-button-click="scrollNext" />
            </div>
          </div>
        </div>

        <BannerCards />
      </div>
    </div>
  </section>
</template>
