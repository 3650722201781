<script lang="ts" setup>
import type { SwiperOptions } from 'swiper'

import CollectionCard from '~/components/cards/CollectionCard.vue'
import ViewAllButton from '~/components/form-elements/ViewAllButton.vue'
import Page from '~/enums/page'
import type { Collection } from '~/types/collections'

defineProps<{
  collectionsData: any[] // TODO
  title: string
}>()

const collections: Collection[] = [
  {
    id: '10',
    name: 'Коррекция микробиоты',
    path: `${Page.Collections}korrekciya-mikrobioty-ot-diany-sharonovoj-20/`
  },
  {
    id: '1',
    name: 'После праздников',
    path: `${Page.Collections}nabor-posle-prazdnikov-ot-anny-gorbachevoy-at-vrach-gorbacheva-19/`
  },
  {
    id: '2',
    name: 'Здоровье груди',
    path: `${Page.Collections}nabor-zdorove-grudi-ot-anny-gosudarevoy-at-anna-gosudareva-18/`
  },
  {
    id: '3',
    name: 'Возвращение энергии',
    path: `${Page.Collections}nabor-vozvraschenie-energii-ot-oksany-saga-at-oxanasaga-17/`
  },
  {
    id: '4',
    name: 'Контроль веса',
    path: `${Page.Collections}nabor-kontrol-vesa-ot-tatyany-naumkinoy-at-naumkina-tatyana-15/`
  },
  {
    id: '5',
    name: 'Защита печени и желчного пузыря',
    path: `${Page.Collections}nabor-zaschita-pecheni-i-zhelchnogo-puzyrya-ot-anny-gosudarevoy-at-anna-gosudareva-14/`
  },
  {
    id: '6',
    name: 'Поддержка для школьника',
    path: `${Page.Collections}nabor-podderzhka-dlya-shkolnika-ot-anny-chitanava-at-ankacheeta-13/`
  },
  {
    id: '7',
    name: 'Восстановление мозга',
    path: `${Page.Collections}nabor-vosstanovlenie-mozga-ot-vladimira-popova-at-detsdoc-12/`
  },
  {
    id: '8',
    name: 'Здоровая беременность',
    path: `${Page.Collections}nabor-zdorovaya-beremennost-ot-olgi-balahontsevoy-i-gulnary-filatovoy-11/`
  },
  {
    id: '9',
    name: 'От поллиноза',
    path: `${Page.Collections}nabor-ot-pollinoza-ot-anny-chitanava-at-ankacheeta-10/`
  },
]
const collectionsPath = Page.Collections
const galleryOptions: SwiperOptions = {
  breakpoints: {
    1020: { slidesPerGroup: 1, slidesPerView: 9 },
    960: { slidesPerGroup: 1, slidesPerView: 7 },
    768: { slidesPerGroup: 1, slidesPerView: 6 },
    480: { slidesPerGroup: 1, slidesPerView: 5.3, spaceBetween: 12 },
    360: { slidesPerGroup: 1, slidesPerView: 4.3, spaceBetween: 12 },
    0: { slidesPerGroup: 1, slidesPerView: 1, spaceBetween: 12 }
  },
  lazy: true,
  spaceBetween: 16
}

const router = useRouter()
</script>

<template>
  <section v-if="collectionsData.length">
    <div class="container">
      <Heading element="h2" :path="collectionsPath" :title="title" title-class="h1">
        <template #buttons>
          <ViewAllButton title="Все подборки" @click="router.push(collectionsPath)" />
        </template>
      </Heading>
    </div>

    <Gallery :options="galleryOptions">
      <CollectionCard v-for="x in collections" :key="x.id" class="max-9-slides swiper-slide" :collection="x" />
    </Gallery>
  </section>
</template>

<style lang="scss" scoped>
@use 'assets/css/gallery';
</style>
