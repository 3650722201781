<script lang="ts" setup>
const props = defineProps<{
  isNext: boolean
}>()

const emits = defineEmits<{
  (e: 'onButtonClick'): void
}>()

const ariaLabel = computed(() => props.isNext ? 'Вперёд' : 'Назад')
const svgIconName = computed(() => props.isNext ? 'outlined/navigate-next' : 'outlined/navigate-before')
</script>

<template>
  <button
    :aria-label="ariaLabel"
    class="flex size-11 cursor-pointer items-center justify-center rounded-full bg-[#5b5b5b]/20"
    @click="emits('onButtonClick')"
  >
    <SvgIcon class="text-2xl" :name="svgIconName" />
  </button>
</template>
