<script lang="ts" setup>
import { bannerCards } from '~/constants/banners'
import type YandexMetrikaGoal from '~/enums/yandex-metrika-goal'
import YandexMetrikaService from '~/services/yandex-metrika-service'

const router = useRouter()

async function onBannerCardClick (
  path: string | undefined,
  url: string | undefined,
  yandexMetrikaGoal: YandexMetrikaGoal
): Promise<void> {
  if (path) {
    await router.push(path)
  } else if (url) {
    window.open(url, '_blank')
  }

  YandexMetrikaService.reachGoal(yandexMetrikaGoal)
}
</script>

<template>
  <div class="mt-5 flex px-2 sm:px-0 xl:ml-4 xl:mt-0 xl:w-[328px] xl:flex-col">
    <div
      v-for="({ buttonText, description, name, path, url, yandexMetrikaGoal }, i) in bannerCards"
      :key="name"
      class="
        flex min-h-[144px] basis-1/2 cursor-pointer flex-col justify-between rounded-3xl p-3 text-main-gray-low
        sm:p-5 xl:h-[174px]
      "
      :class="i === 0 ? 'mr-3 xl:mb-4 xl:mr-0 bg-[#b07bf5]' : 'bg-[#57a0f7]'"
      @click="onBannerCardClick(path, url, yandexMetrikaGoal)"
    >
      <div class="text-[17px] leading-[1.08] tracking-[0em] font-medium md:tracking-base xl:text-[26px]">
        {{ name }}
      </div>

      <div class="flex flex-col">
        <div
          class="
            text-[13px] leading-[1.38] tracking-[-0.02em] mb-2
            sm:text-[15px] md:tracking-[-0.01em] lg:text-base lg:mb-3
          "
        >
          {{ description }}
        </div>

        <div class="flex items-center">
          <span class="block text-sm leading-[1.43] font-medium mr-1 md:text-base md:leading-normal md:tracking-[-0.01em]">
            {{ buttonText }}
          </span>

          <SvgIcon name="outlined/arrow-next" class="!size-5 !text-main-gray-low" />
        </div>
      </div>
    </div>
  </div>
</template>
